import React, { useState } from "react";
import { Link } from "react-router-dom";
import LogoLight from "../../../assets/final-tripa-logo.svg";

export default function Footer() {
  const [isBusiness, setIsBusiness] = useState(false);

  React.useEffect(() => {
    currentPathIsBusiness();
  }, []);
  const currentPathIsBusiness = () => {
    var loc = window.location.pathname;
    var dir = loc.substring(0, loc.lastIndexOf("/"));
    if (dir === "/business") {
      setIsBusiness(true);
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <footer class="footer">
        <div class="container">
          <div class="main-footer">
            <div class="footer-widgets" id="footer-widgets-one">
              <img src={LogoLight} alt="tripa-logo" class="footer-logo" />
              <ul class="list-item social-icon">
                <li class="social-icon-style">
                  <a
                    href="https://www.facebook.com/TRIPAInsights/"
                    target="_blank"
                    rel="noreferrer"
                    class="social-icon-item"
                  >
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                <li class="social-icon-style">
                  <a
                    href="https://www.linkedin.com/company/tripa/"
                    class="social-icon-item"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa-brands fa-linkedin-in"></i>
                  </a>
                </li>
                <li class="social-icon-style">
                  <a
                    href="https://www.instagram.com/tripainsights/"
                    class="social-icon-item"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div id="footer-widgets-two" className={[
                "footer-widgets",
                isBusiness ? "business-style" : "coaching-style",
              ].join(" ")}>
              <h3 class="footer-widget-title">Sitemap</h3>
              <ul class="list-item">
                <li class="menu-list">
                  <a href={`https://tripa.ai${ isBusiness ? "/#features" : "/coaching/#features" }`} target="_blank" rel="noreferrer">
                    FEATURES
                  </a>
                </li>
                <li class="menu-list">
                  <a href={`https://tripa.ai${ isBusiness ? "/about" : "/coaching/about-tripa-human-assessment-platform" }`} target="_blank" rel="noreferrer">
                    ABOUT
                  </a>
                </li>
                <li class="menu-list">
                  <a href={`https://tripa.ai${ isBusiness ? "/#team" : "/coaching/#team" }`} target="_blank" rel="noreferrer">
                    TEAM
                  </a>
                </li>
                <li class="menu-list">
                  <a href={`https://tripa.ai${ isBusiness ? "/#contactus" : "/coaching/#contactus" }`} target="_blank" rel="noreferrer">
                    CONTACT
                  </a>
                </li>
              </ul>
            </div>

            <div id="footer-widgets-three" className={[
                "footer-widgets",
                isBusiness ? "business-style" : "coaching-style",
              ].join(" ")}>
              <h3 class="footer-widget-title">Informations</h3>
              <ul class="list-item">
                <li class="menu-list">
                  <a href={`https://tripa.ai${ isBusiness ? "/general-terms-conditions/" : "/coaching/general-terms-conditions-2/" }`} target="_blank" rel="noreferrer">
                    Terms of Use and Conditions
                  </a>
                </li>
                <li class="menu-list">
                  <a href={`https://tripa.ai${ isBusiness ? "/privacy-policy/" : "/coaching/privacy-policy/" }`} target="_blank" rel="noreferrer">Privacy Policy</a>
                </li>
                <li class="menu-list">
                  <a href={`https://tripa.ai${ isBusiness ? "/cookie-policy/" : "/coaching/cookie-policy/" }`} target="_blank" rel="noreferrer">Cookie Settings</a>
                </li>
              </ul>
            </div>

            <div id="footer-widgets-four" className={[
                "footer-widgets",
                isBusiness ? "business-style" : "coaching-style",
              ].join(" ")}>
              <h3 class="footer-widget-title">Contact Us</h3>
              <ul class="list-item info-icon">
                <li class="menu-list">
                  <a href="tel:+19548540755" class="info-widget">
                    <i class="fa-solid fa-phone"></i>
                    <span> +1 (954) 854-0755</span>
                  </a>
                </li>
                <li class="menu-list !pt-0">
                  <a href="tel:+421907207601" class="info-widget icon-op-0">
                    <i class="fa-solid fa-phone"></i>{" "}
                    <span>+421-907-207-601</span>
                  </a>
                </li>
                <li class="menu-list !pt-[5px] !pb-[5px]">
                  <a href="mailto:info@tripa.ai" class="info-widget">
                    <i class="fa-solid fa-envelope"></i>{" "}
                    <span>info@tripa.ai</span>
                  </a>
                </li>
                <li class="menu-list !pt-[5px]">
                  <a
                    href="https://goo.gl/maps/45koooWPcSbnqXer5"
                    class="info-widget"
                  >
                    <i class="fa-solid fa-location-dot"></i>
                    <span>
                      3960 SW 195 Terrace
                      <br />
                      Miramar, Fl 33029, United States Žlta 7, Bratislava 85107,
                      Slovakia
                    </span>
                  </a>
                </li>
                <li class="menu-list !pt-0">
                  <a
                    href="https://maps.app.goo.gl/Ngt2JxPq96oLpEtx7"
                    class="info-widget icon-op-0"
                  >
                    <i class="fa-solid fa-location-dot"></i>
                    <span>
                      Košická 52/A, Bratislava 821 08, Slovakia
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="bottom-section">
            <p>Copyright ©2018 – 2024, TRIPA Inc & TRIPA HC. – All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
}
