import React, { useState } from "react";
import OutlineSuccessBtn from "../buttons/outlineSuccessBtn/OutlineSuccessBtn";
import { useNavigate } from "react-router-dom";
import{ ReactComponent as TripaLogo } from "../../../assets/logo.svg";
import LogoDark from "../../../assets/tripa-dark-logo.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CryptoJS from "crypto-js";
import config from "../../../../config/config";
import Cookies from "universal-cookie";

export default function Header({ logo }) {
  const [menu, setMenu] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeStyle = "";
  React.useEffect(() => {

    currentPathIsBusiness();
  }, []);
  const currentPathIsBusiness = () => {
    var loc = window.location.pathname;
    var dir = loc.substring(0, loc.lastIndexOf("/"));
    if (dir === "/business") {
      setIsBusiness(true);
      return true;
    } else {
      return false;
    }
  };
  React.useEffect(() => {
    var loc = window.location.pathname;
    var dir = loc.substring(0, loc.lastIndexOf("/"));
    const token = localStorage.getItem("access");
    // role section
    const ciphertext = localStorage.getItem("role");
    var role;
    var bytes;
    if ( ciphertext){
    bytes = CryptoJS.AES.decrypt(ciphertext, config.cookieSecret);
    role = JSON.parse(bytes?.toString(CryptoJS.enc.Utf8));
  }
    // ---------
    //keep me login
    const keepMeLoginText = localStorage.getItem("keepMe");
    var bytesLogin;
    var keepMeLogin;
    if ( keepMeLoginText){
    bytesLogin = CryptoJS.AES.decrypt(keepMeLoginText, config.cookieSecret);
    keepMeLogin = JSON.parse(bytesLogin?.toString(CryptoJS.enc.Utf8));
    }
    // ------
    console.log( 'header' ,dir, token, role , keepMeLogin)
    if ( dir === '/business' && token && role === 'company' && keepMeLogin ){
      navigate("/business/dashboard");
    }
    if ( dir === '/coaching' && token && role === 'job_candidate' && keepMeLogin ){
      const accessData = localStorage.getItem("accessData");
      if ( accessData){
        const { isFirstNameAndLastNameCompleted } = accessData;
          if (accessData.is_first) {
            const {
              isAssessmentTypeCompleted,
              isBehaviorAssessmentCompleted = true,
              isDriverOfEngagementDiagnosticCompleted,
              isRoleBasedAssessmentCompleted,
              isRoleBasedCompetanceAssessmentCompleted,
            } = accessData;
            if (!isAssessmentTypeCompleted) {
              navigate("/assessment/start", {
                state: {
                  behaviorAssessmentCode: isBehaviorAssessmentCompleted,
                },
              });
            } else if (isBehaviorAssessmentCompleted !== true) {
              navigate("/assessment/behavior/start", {
                state: {
                  behaviorAssessmentCode: isBehaviorAssessmentCompleted,
                },
              });
            } else if (isDriverOfEngagementDiagnosticCompleted !== true) {
              navigate("/assessment/values/start");
            } else if (
              isRoleBasedAssessmentCompleted !== true ||
              isRoleBasedCompetanceAssessmentCompleted !== true
            ) {
              navigate("/assessment/rolebased/start");
            } else if (isFirstNameAndLastNameCompleted) {
              navigate("/resumeform/upload");
            }
          } else if (isFirstNameAndLastNameCompleted) {
            navigate("/resumeform/upload");
          } else {
            navigate("/coaching/profile");
          }
      }
    }
    else {
      localStorage.removeItem('token-session');
      localStorage.removeItem('access');
      localStorage.removeItem('role');
      localStorage.removeItem('keepMe');
    }
      // navigate("/business/dashboard");
  }, []);
  return (
    <header className="header px-6 py-[10px] border-b-2 relative bg-white">
      <div className="header-container">
        <a href="https://tripa.ai">
          <img src={LogoDark} alt="tripa-logo" className="h-[60px] w-auto" />
        </a>
        <nav
          className={`transition-all lg:flex lg:static  absolute left-0 top-[75px]
         bg-white lg:bg-transparent w-full p-6 lg:p-0 lg:min-h-0 h-auto min-h-[360px]
        lg:flex-row lg:justify-end lg:items-center z-[999] ${
          menu ? "static" : "hidden"
        }`}
        >
          <ul
            className={`flex lg:flex-row lg:justify-end lg:items-center flex-col justify-around lg:h-auto h-[260px]`}
          >
            <OutlineSuccessBtn
              className={[
                "mx-3 header-normal-btn",
                isBusiness ? "normal-btn-business" : "normal-btn-coaching",
              ].join(" ")}
              customStyle={{
                "background-color": isBusiness ? "#B07FAA" : "#7AB794",
                color: "white",
                width: "120px ",
                height: "40px ",
              }}
            >
              <a
                href={`https://tripa.ai${ isBusiness ? "" : "/coaching" }`}
                className={({ isActive }) =>
                  `text-base rounded transition-all lg:mr-2 ${
                    isActive
                      ? "bg-success text-white p-2  "
                      : "bg-transparent text-dark p-2  "
                  }`
                }
              >
                HOME{" "}
              </a>
            </OutlineSuccessBtn>
            &nbsp;
            <OutlineSuccessBtn
              className={[
                "mx-3 header-normal-btn",
                isBusiness ? "normal-btn-business" : "normal-btn-coaching",
              ].join(" ")}
              customStyle={{
                "background-color": isBusiness ? "#B07FAA" : "#7AB794",
                color: "white",
                width: "120px ",
                height: "40px ",
              }}
            >
              <a
                href={`https://tripa.ai${ isBusiness ? "/#features" : "/coaching/#features" }`}
                className={({ isActive }) =>
                  `text-base rounded transition-all lg:mr-2 ${
                    isActive
                      ? "bg-success text-white p-2  "
                      : "bg-transparent text-dark p-2  "
                  }`
                }
              >
                FEATURES{" "}
              </a>
            </OutlineSuccessBtn>
            &nbsp;
            <OutlineSuccessBtn
              className={[
                "mx-3 header-normal-btn",
                isBusiness ? "normal-btn-business" : "normal-btn-coaching",
              ].join(" ")}
              customStyle={{
                "background-color": isBusiness ? "#B07FAA" : "#7AB794",
                color: "white",
                width: "120px ",
                height: "40px ",
              }}
            >
              <a
                href={`https://tripa.ai${ isBusiness ? "/#team" : "/coaching/#team" }`}
                className={({ isActive }) =>
                  `text-base rounded transition-all lg:mr-2 ${
                    isActive
                      ? "bg-success text-white p-2  "
                      : "bg-transparent text-dark p-2  "
                  }`
                }
              >
                TEAM{" "}
              </a>
            </OutlineSuccessBtn>
            &nbsp;
            <OutlineSuccessBtn
              className="mx-3 header-contact-btn"
              customStyle={{
                "background-color": "#384259",
                color: "white",
                width: "120px ",
                height: "40px ",
              }}
            >
              <a
                href={`https://tripa.ai${ isBusiness ? "/#contactus" : "/coaching/#contactus" }`}
                className={({ isActive }) =>
                  `text-base rounded transition-all lg:mr-2 ${
                    isActive
                      ? "bg-success text-white p-2  "
                      : "bg-transparent text-dark p-2  "
                  }`
                }
              >
                CONTACT{" "}
              </a>
            </OutlineSuccessBtn>

          </ul>

          <OutlineSuccessBtn
            className="mx-3 header-contact-btn"
            customStyle={{
              "background-color": isBusiness ? "#B07FAA" : "#7AB794",
              color: "white",
              width: "120px ",
              height: "40px ",
            }}
          >
            <a
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              LOGIN <i className="fas fa-chevron-down" />
            </a>
          </OutlineSuccessBtn>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >{!isBusiness ? 
            <MenuItem onClick={() => navigate("/business/login")}>
              Business
            </MenuItem>
            :
            <MenuItem onClick={() => navigate("/coaching/login")}>
              Coaching
            </MenuItem>
            }
          </Menu>
        </nav>

        <input
          type="checkbox"
          value={menu}
          onChange={() => setMenu((menu) => !menu)}
          id="checkbox"
          className="checkbox hidden"
        />
        <label
          htmlFor="checkbox"
          id="bars"
          className={`cursor-pointer transition-all duration-700 block lg:hidden ${
            menu && "rotate-90 select-none"
          }`}
        >
          <div
            className={`transition-all duration-500 delay-500 w-[30px] h-[3px] my-[5px] rounded bg-black ${
              menu && "rotate-[-135deg] translate-y-[10px] select-none"
            }`}
          ></div>
          <div
            className={`transition-all duration-500 delay-500 w-[30px] h-[3px] my-[5px] rounded bg-black ${
              menu && "opacity-0 select-none"
            }`}
          ></div>
          <div
            className={`transition-all duration-500 delay-500 w-[30px] h-[3px] my-[5px] rounded bg-black ${
              menu && "rotate-[-45deg] translate-y-[-6.5px] select-none"
            }`}
          ></div>
        </label>
      </div>
    </header>
  );
}
