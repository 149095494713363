import React from 'react'
import InfoBoxes from '../../sharedComponents/profile/infoBox'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSelector } from 'react-redux';
import moment from 'moment';
export default function AboutMe({ handleClickEdit}) {
  const user = useSelector(state => state.user);
  const worksAt = user?.work_experience?.find(x => x.isPresent)
  const sortedEducation = user.education ? [...user.education].sort((a, b) => new Date(b.to_date) - new Date(a.to_date)) : [];
  
  return (
    <>
      <InfoBoxes title="About me" icon={<EditOutlinedIcon onClick={handleClickEdit} />} TooltipText="Briefly summarize the essential information you want a hiring manager or human resource professional to know from your resume. The information should be brief and to the point, emphasizing the most important and relevant skills and experience for the position being applied for." TooltipTitle="About me">
        <div className='text-base leading-5 pb-4 mb-4 border-b border-gray-300'>
          {user?.about_me}
        </div>
        <div className='grid md:grid-cols-3 grid-cols-2'>
          <div className='flex flex-col justify-start my-2'>
            <div className='leading-5 text-base'>Works at</div>
            <div className='text-[14px] text-secondaryBalck'>  {worksAt?.company_name ||  user?.work_experience?.[0]?.company_name}  </div>
          </div>
          <div className='flex flex-col justify-start my-2'>
            <div className='leading-5 text-base'>Went to</div>
            <div className='text-[14px] text-secondaryBalck'>{sortedEducation ? sortedEducation[0]?.majors_obtained : '-'}</div>
          </div>
          <div className='flex flex-col justify-start my-2'>
            <div className='leading-5 text-base'>Date of Birth</div>
            <div className='text-[14px] text-secondaryBalck'>{user?.birthday && moment(user?.birthday).format('MM/YYYY')}</div>
          </div>
          <div className='flex flex-col justify-start my-2'>
            <div className='leading-5 text-base'>Email</div>
            <div className='text-[14px] text-secondaryBalck'>{user?.email_id}</div>
          </div>
          <div className='flex flex-col justify-start my-2'>
            <div className='leading-5 text-base'>Phone</div>
            <div className='text-[14px] text-secondaryBalck'>{user?.country_code} {user?.phone_number}</div>
          </div>
        </div>
      </InfoBoxes>
    </>
  )
}
